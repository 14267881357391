.user-note{
    background: #005F95;
    color: white;
}
.small-text{
    font-size: 13px;
}
.user-stats{
    background: #FFFFFF33;
    border-radius: 50%;
    height: 100px;
    display: grid;
    gap: 0px;
    width: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.customCheckBox{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    border: 1px solid white; /* White border */
    /*width: 20px; !* Customize the size *!*/
    /*height: 20px; !* Customize the size *!*/
    background-color: transparent; /* Transparent background */
    outline: none; /* Remove default focus styles */
    position: relative; /* Position for checkmark */
}

/* Create a custom checkbox */
.customCheckBox:checked::before {
    content: '\2713'; /* Checkmark character */
    display: block;
    text-align: center;
    color: white; /* White check marker */
    font-size: 18px; /* Customize the size */
    position: absolute; /* Position checkmark absolutely */
    top: -4px; /* Adjust vertical positioning */
    left: 1px;
}
