@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --light-blue-color: #b1cbcf;
    --dark-blue-color: #005662;
    --blue-color: #005662
}

@layer base {
    img {
        height: inherit;
    }
}

@font-face {
    font-family: "archivo-regular";
    src: url("../public/fonts/archivo/archivo-expanded-regular.ttf");
}

@font-face {
    font-family: "archivo-bold";
    src: url("../public/fonts/archivo/archivo-expanded-bold.ttf");
}

@font-face {
    font-family: "archivo-light";
    src: url("../public/fonts/archivo/archivo-expanded-extralight.ttf");
}

@layer utilities {
    .font-bold {
        @apply !font-archivo-bold
    }
}

body {
    font-family: "archivo-regular";
}

.login {
    border-radius: 20px;
    border: none;
    padding: 10px 10px;
    background-color: var(--light-blue-color);
    color: white;
    outline: none;
    width: 100%;
}

.MuiDrawer-modal .MuiPaper-elevation {
    position: fixed;
    inset: 0;
    background-color: #0004;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(177, 203, 207) inset !important;
    -webkit-text-fill-color: white !important;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
input:-moz-autofill:active {
    box-shadow: 0 0 0 30px rgb(177, 203, 207) inset !important;
    text-fill-color: white !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.slick-prev:before, .slick-next:before {
    display: none;
}


.checkbox input[type="checkbox"] {
    width: 30px;
    height: 3rem;
    accent-color: #F96300;
}

/*.date-picker .MuiInputBase-input {*/
/*    padding-top: 10px;*/
/*}*/
.date-picker .MuiOutlinedInput-input {
    padding-top: 15px;
}

.date-picker .MuiInputLabel-formControl {
    letter-spacing: normal;
}

.gender .MuiInputBase-colorPrimary {
    padding: 0;
    border-radius: 50px;
    color: white;
}

.gender .MuiInputLabel-root, .gender .MuiSvgIcon-fontSizeMedium {
    color: white;
}

/*.search::placeholder{*/
/*    color: white;*/
/*}*/
.date-input {
    border-radius: 20px;
    border: none;
    padding: 15px 15px;
    background-color: white;
    font-weight: bold;
    font-size: 18px;
    color: #005662;
    outline: none;
    width: 100%;
}

.label-admin {
    color: #005662
}

.date-input-admin {
    border-radius: 20px;
    border: 1px solid #005662;
    padding: 15px 15px;
    /*font-weight: bold;*/
    font-size: 18px;
    color: #005662;
    outline: none;
    width: 100%;
}

.MuiPickersCalendarHeader-label {
    font-family: 'archivo-regular'
}

.login::placeholder {
    color: #edeff0;
}

.login:focus-visible {
    border: 1px solid var(--dark-blue-color)
}


.primary-button {
    border-radius: 20px;
    border: none;
    padding: 10px 10px;
    background-color: var(--dark-blue-color);
    color: white;
    /*font-weight: bold;*/
}

.primary-button-reversed {
    border-radius: 20px;
    border: none;
    padding: 10px 30px;
    background-color: white;
    color: var(--dark-blue-color);
    /*font-weight: bold;*/
}

.primary-button-no-border {
    padding: 10px 10px;
    background-color: var(--dark-blue-color);
    color: white;
    /*font-weight: bold;*/
}

.secondary-button {
    border-radius: 38px;
    min-width: 200px;
    border: none;
    width: 100%;
    padding: 10px 10px;
    background-color: white;
    color: var(--blue-color);
    /*font-weight: bold;*/
}

.secondary-button-reversed {
    border-radius: 38px;
    min-width: 200px;
    border: none;
    width: 100%;
    padding: 10px 10px;
    background-color: white;
    color: var(--blue-color);
    /*font-weight: bold;*/
}

.my-account-button {
    border-radius: 20px;
    border: none;
    padding: 10px 10px;
    background-color: #b1cbcf;
    color: var(--dark-blue-color);
    /*font-weight: bold;*/
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

.attach-image-label {
    cursor: pointer;
    border-radius: 10px;
    color: #005662;
    text-decoration: underline;
}

.fullscreen-backdrop {
    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1301;
    }
}
@media (max-width: 768px) {
    .slick-prev,
    .slick-next {
        top: 35% !important;
    }
}
.gm-style-iw-ch, .gm-style-iw-chr{
    display: none;
}

.restaurant-card::before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 10px;
     height: 100%;
     background-color: white;
     border-top-left-radius: 12px;
     border-bottom-left-radius: 12px;
     transition: background-color 0.3s ease;
 }
.restaurant-card.selected::before {
    background-color: #004d40;
}
pre {
    font-family: inherit;
}
/*.css-ahj2mt-MuiTypography-root{*/
/*    font-family:unset !important;*/
/*}*/
.MuiTypography-root {
    font-family:unset !important;
}
