.report-header{
    height: 50vh;
    background-size: cover;
    user-select: none;
    background-position: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color:white
}
.overlay-report {
    position: relative;
    min-height: 100%;
    background-color: rgba(0,95,149, 0.27);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.report-stats{
    background: #6699a1;
    border-radius: 40px;
    padding: 3px 25px;
}
