.card-item{
    background: #EBF2F3;
    border-radius: 20px;
    padding: 10px;
    height: 100px;
    display: grid;
    align-items: center;
}

.card-item-text{
    color:#005662
}
.card-item-small-text{
    color:#005662;
    font-size: 12px;
    margin:0px;
}


@media (max-width: 767px) {
    .card-item{
        height: 125px;
    }
}

