.background{
    width: 100%;
    height: 65vh;
    display: grid;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image:url("../../../public/main-background.png");
    background-color:#B1CBCF; /* The color you want to blend */
    background-blend-mode: multiply;
    opacity: 0.85;
}
.forgot-password{
    color: var(--light-blue-color);
    cursor: pointer;
}
