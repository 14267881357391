.about-us-card{
    background-color: #005662;
    border-radius: 20px;
    opacity: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: -70px;
    padding-bottom: 20px;
}
.tag-reversed{
    background-color: white  ;
    padding: 2px 10px;
    color:#005662;
    /*font-weight: bold;*/
    border-radius: 13px;
    width: fit-content;
}

.about-us-card{
    color: white;
}

.note-card{
    padding: 5px;
    background-color: #FFF0F0;
    color: #FF3636;
    border-radius: 5px;
}

.services{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EBF2F388;
    border-radius: 50%;
    height: 90px;
    font-size: 14px;
    width: 90px;
}
